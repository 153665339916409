var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c("breadcrumb-header", {
        attrs: { labels: ["New client"], go_back_to: "management_clients" },
      }),
      _c(
        "div",
        { staticClass: "inner" },
        [
          _vm.$store.getters.is_xero_enabled
            ? _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "form-wrapper",
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { "auto-complete": "on", "label-width": "160px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Import from Xero", prop: "project" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            remote: "",
                            "remote-method": _vm.remoteMethod,
                            loading: _vm.loading,
                            value: _vm.contact,
                            placeholder: "Search contact",
                            "value-key": "id",
                          },
                          on: { change: _vm.changedContact },
                        },
                        _vm._l(_vm.options, function (c) {
                          return _c("el-option", {
                            key: c.id,
                            attrs: { label: c.name, value: c },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("hr"),
          _vm.contact.id
            ? _c("el-alert", { attrs: { type: "warning", closable: false } }, [
                _vm._v(" Imported clients are not editable. "),
              ])
            : _vm._e(),
          _c("client-form", {
            ref: "clientForm",
            attrs: {
              client: _vm.contact,
              readonly: !!_vm.contact.id,
              loading: _vm.loading,
            },
            on: { submit: _vm.handleSubmit },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }